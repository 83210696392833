import styled from 'styled-components';
import { Card, Typography, TextField, Button } from '@mui/material';

export const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
`;

export const BodyStyled = styled(Card)`
  flex-grow: 1;
  padding: 20px;
`;

export const FormTitleStyled = styled.h3`
  font-size: 22px;
  margin: 10px 0;
`;

export const FormSubTitleStyled = styled.span`
  font-size: 16px;
`;

export const FormContainerStyled = styled.div`
  margin: 15px 0;
`;

export const TextFieldStyled = styled(TextField)`
  margin-top: 20px !important;
`;

export const ButtonStyled = styled(Button)`
  margin-top: 20px !important;
  background: #4ca2ff !important;
`;

export const LinkAssociateStyled = styled.div`
  margin-top: 20px !important;
`;

export const ErrorMessageStyled = styled(Typography)`
  color: #e75252;
  font-size: 12px !important;
  margin-top: 5px !important;
  font-weight: bold !important;
`;

export const ErrorMessageFormStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  background: #e75252;
  font-size: 14px;
  margin-top: 10px;
  padding: 10px 15px;
  border-radius: 5px;

  .close {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #ErrorMessageFormStyledButton {
    color: #ffffff;
  }
`;
