import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { useDropzone } from 'react-dropzone';
import logo from '../../assets/image/logo.jpg';
import CropPhoto from '../../components/CropPhoto';
import {
  ButtonStyled,
  CardContentLeftStyled,
  CardContentRightStyled,
  CardContentStyled,
  CardHeaderLogoContainerStyled,
  CardHeaderStyled,
  CardHeaderTitleStyled,
  CardStyled,
  CardCustomStyled,
  CorpDataStyled,
  ErrorMessageCardStyled,
  LogoStyled,
  UploadPhotoDropzoneComponentStyled,
  UploadPhotoStyled,
  UserDataStyled,
  UserPhotoStyled,
  WarningMessageCardStyled,
  CardOptionsStyled,
  ButtonLoadingStyled,
  ButtonChangePhotoStyled,
} from './style';
import associateService from '../../services/http/associateService';
import associateDataService from '../../services/store/associateDataService';
import dateUtils from '../../utils/dateUtils.js';
import screenshotUtils from '../../utils/screenshotUtils.js';
import { IconButton } from '@mui/material';
// import googleWalletButton from '../../assets/image/google-wallet.png';
// import appleWalletButton from '../../assets/image/apple-wallet.png';

const CardPage = () => {
  const screenshotRef = useRef(null);

  const navigate = useNavigate();
  // const [googleWalletUrl, setGoogleWalletUrl] = useState(null);
  const [cardData, setCardData] = useState(null);
  const [warningMessage, setWarningMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isPhotoUpdateRequired, setIsPhotoUpdateRequired] = useState(false);

  const [cropConfig, setCropConfig] = useState({});
  const [loadingSendPhoto, setLoadingSendPhoto] = useState(false);
  // const [loadingGoogleWallet, setLoadingGoogleWallet] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [fileListThumbnail, setFileListThumbnail] = useState([]);

  const allowedFileExtensions = ['.png', '.jpg', '.jpeg'];

  const handleSetCardData = (data) => {
    const cardDataTemp = {
      id: data.id,
      name: data.name,
      email: data.email,
      cpf: data.cpf,
      photo: data.photo_url,
      expirationDate: data.expires_at,
      bank_registration_number: data.bank_registration_number,
      date_admission: data.date_admission,
      bank_name: data.bank_name,
      bank_code: data.bank_code,
    };

    if (
      !cardDataTemp.name ||
      !cardDataTemp.cpf ||
      !cardDataTemp.photo ||
      !cardDataTemp.expirationDate
    ) {
      setWarningMessage(
        'Você precisa enviar uma foto para efetuar a geração da carteira. Por favor, escolha uma foto abaixo.',
      );
      setIsPhotoUpdateRequired(true);
    } else {
      setWarningMessage(null);
      setIsPhotoUpdateRequired(false);
    }

    setCardData(cardDataTemp);
  };

  const { fileRejections, getRootProps, getInputProps, isFocused } =
    useDropzone({
      accept: {
        image: allowedFileExtensions,
      },
      maxFiles: 1,
      onDrop: (acceptedFiles) => {
        setFileList(acceptedFiles);

        setFileListThumbnail(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            }),
          ),
        );
      },
    });

  const getErrorType = (code, extensions) => {
    switch (code) {
      case 'file-invalid-type':
        return `São permitidos apenas arquivos do tipo: ${extensions.join(
          ', ',
        )}`;
      case 'too-many-files':
        return 'Por favor, selecione apenas 1 arquivo';
      default:
        break;
    }

    return null;
  };

  const getFileChooseErrors = useCallback((files, extensions) => {
    const errorMessageList = files.map(({ file, errors }) => {
      const alert = errors.map((error) => getErrorType(error.code, extensions));
      return `Arquivo: ${file.path}. Aviso(s): ${alert.join(', ')}`;
    });

    if (errorMessageList && errorMessageList.length > 0) {
      return errorMessageList;
    }

    return [];
  }, []);

  const errorListSelectPhotos = getFileChooseErrors(
    fileRejections,
    allowedFileExtensions,
  );

  function changeFileExtensionToJPEG(file, index) {
    return new File([file], `${'FOTO'}_${+index}.jpeg`, { type: 'image/jpeg' });
  }

  const sendPhoto = () => {
    setLoadingSendPhoto(true);

    const cropConfigRequest = {
      unit: cropConfig.unit,
      imageWidth: cropConfig.imageWidth,
      imageHeight: cropConfig.imageHeight,
      coordCropX: cropConfig.x < 0 ? 0 : cropConfig.x,
      coordCropY: cropConfig.y < 0 ? 0 : cropConfig.y,
      sizeCropX: cropConfig.width,
      sizeCropY: cropConfig.height,
    };

    const formData = new window.FormData();

    formData.append('cropConfig', JSON.stringify(cropConfigRequest));

    fileList.forEach((file, index) => {
      const fileExtensionJPEG = changeFileExtensionToJPEG(file, index);
      formData.append('file', fileExtensionJPEG);
    });

    formData.append('id', cardData.id);
    formData.append('cpf', cardData.cpf);
    formData.append(
      'bank_registration_number',
      cardData.bank_registration_number,
    );
    formData.append('date_admission', cardData.date_admission);

    const defaultErrorMessage =
      'Por favor, entre em contato com nossa equipe. Os dados de contato, estão no final dessa página.';

    associateService
      .cropAndUpdatePhoto(formData)
      .then((response) => {
        const { data } = response;

        if (data.status === 'error') {
          setErrorMessage(`${data.message} ${defaultErrorMessage}`);
          console.error('Error:', data);
        } else {
          setErrorMessage(null);

          const extraParams = data.data;

          const updateParams = {
            ...cardData,
            ...extraParams,
          };

          associateDataService.update(updateParams);
          handleSetCardData(updateParams);

          setFileList([]);
          setFileListThumbnail([]);
        }
      })
      .catch((error) => {
        setErrorMessage(
          `Ocorreu um erro inesperado no momento de salvar sua foto em nossa base de dados. ${defaultErrorMessage}`,
        );
        console.error(error);
      })
      .finally(() => {
        setLoadingSendPhoto(false);
      });
  };

  const handleDownload = () => {
    screenshotUtils.screenshot(screenshotRef, 'cateira-convenio-seeb.jpg');
  };

  // const openNewTab = (url) => {
  //   window.open(url, '_blank');
  // };

  // const getGoogleWalletOptions = () => {
  //   setLoadingGoogleWallet(true);

  //   const defaultErrorMessage =
  //     'Por favor, entre em contato com nossa equipe. Os dados de contato, estão no final dessa página.';

  //   const params = {
  //     name: cardData.name,
  //     cpf: cardData.cpf,
  //     email: cardData.email,
  //     expirationDate: cardData.expirationDate,
  //     photo: cardData.photo,
  //   };

  //   associateService
  //     .getGoogleWalletOptions(params)
  //     .then((response) => {
  //       const { data } = response;

  //       if (data.status === 'error') {
  //         setErrorMessage(`${data.message} ${defaultErrorMessage}`);
  //         console.error('Error:', data);
  //       } else {
  //         setErrorMessage(null);
  //         setGoogleWalletUrl(data.url);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error('Error:', error);
  //       setErrorMessage(
  //         `Ocorreu um erro em nosso sistema no momento de validar seus dados. ${defaultErrorMessage}`,
  //       );
  //     })
  //     .finally(() => {
  //       setLoadingGoogleWallet(false);
  //     });
  // };

  const enableChangePhoto = () => {
    setIsPhotoUpdateRequired(true);
  };

  const disableChangePhoto = () => {
    setIsPhotoUpdateRequired(false);
  };

  useEffect(() => {
    const data = associateDataService.get();

    if (!data?.id) {
      navigate('/');
      return;
    }

    handleSetCardData(data);
  }, []);

  // useEffect(() => {
  //   if (cardData) {
  //     getGoogleWalletOptions();
  //   }
  // }, [cardData]);

  return (
    <>
      {warningMessage && (
        <WarningMessageCardStyled>
          <div className="icon">
            <WarningIcon id="WarningMessageCardStyledButton" />
            {warningMessage}
          </div>
        </WarningMessageCardStyled>
      )}

      {errorMessage && (
        <ErrorMessageCardStyled>
          <div className="icon">
            <ErrorIcon id="ErrorMessageCardStyledButton" />
            {errorMessage}
          </div>
        </ErrorMessageCardStyled>
      )}

      {isPhotoUpdateRequired && (
        <CardStyled>
          <UploadPhotoStyled>
            <div className="header">
              <h3>Enviar foto da carteira</h3>
              <IconButton aria-label="close" onClick={disableChangePhoto}>
                <CloseIcon />
              </IconButton>
            </div>
            {errorListSelectPhotos && errorListSelectPhotos.length > 0 && (
              <ErrorMessageCardStyled>
                <div className="icon">
                  <ErrorIcon id="ErrorMessageCardStyledButton" />
                  <div>
                    <strong>Erros na escolha da foto:</strong>
                    <br />
                    <div className="list">
                      {errorListSelectPhotos.map((error) => (
                        <div className="item" key={error}>
                          &bull; {error}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </ErrorMessageCardStyled>
            )}

            <UploadPhotoDropzoneComponentStyled
              {...getRootProps({ className: 'dropzone', isFocused })}
            >
              <input {...getInputProps()} />
              <p>
                arraste a foto ({allowedFileExtensions.join(', ')}) até aqui
                <br />
                ou clique aqui para escolher
              </p>
            </UploadPhotoDropzoneComponentStyled>

            {fileListThumbnail.map((file) => (
              <CropPhoto
                key={file.name}
                photoBuffer={file.preview}
                onCropChange={setCropConfig}
              />
            ))}

            {fileListThumbnail.length === 1 && (
              <ButtonLoadingStyled
                id="ButtonLoadingStyled"
                fullWidth
                variant="contained"
                type="submit"
                disabled={loadingSendPhoto}
                onClick={sendPhoto}
                loading={loadingSendPhoto}
                loadingPosition="start"
              >
                <span>{loadingSendPhoto ? 'Enviando...' : 'Enviar foto'}</span>
              </ButtonLoadingStyled>
            )}
          </UploadPhotoStyled>
        </CardStyled>
      )}

      {cardData && (
        <>
          <CardCustomStyled ref={screenshotRef}>
            <CardHeaderStyled>
              <CardHeaderLogoContainerStyled>
                <LogoStyled src={logo} alt="Logo seeb" />
              </CardHeaderLogoContainerStyled>
              <CardHeaderTitleStyled>
                <h3>Carteira de Associado</h3>
              </CardHeaderTitleStyled>
            </CardHeaderStyled>
            <CardContentStyled>
              <CardContentLeftStyled>
                {cardData.photo && (
                  <UserPhotoStyled
                    src={cardData.photo}
                    alt="Foto da carteirinhas"
                  />
                )}
                {!cardData.photo && (
                  <div className="empty-photo">
                    <AddPhotoAlternateIcon className="icon" />
                    <span className="text">
                      Por favor, escolha uma foto acima
                    </span>
                  </div>
                )}
              </CardContentLeftStyled>
              <CardContentRightStyled>
                <UserDataStyled>
                  <div className="row">
                    <div className="col">
                      <div className="label">NOME</div>
                      <div className="value">{cardData.name}</div>
                    </div>
                    <div className="col">
                      <div className="label">CPF</div>
                      <div className="value">{cardData.cpf}</div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="label">Banco</div>
                      <div className="value">{cardData.bank_name}</div>
                    </div>
                    <div className="col">
                      <div className="label">VALIDADE</div>
                      <div className="value">
                        {cardData.expirationDate && (
                          <>{dateUtils.format(cardData.expirationDate)}</>
                        )}
                        {!cardData?.expirationDate && (
                          <span>Carteira inválida</span>
                        )}
                      </div>
                    </div>
                  </div>
                </UserDataStyled>
                <CorpDataStyled>
                  <div>
                    <strong>
                      Sindicato dos Bancários de Uberlândia e Região
                    </strong>
                  </div>
                  <div className="contact">
                    <div className="row">bancariosuberlandia@gmail.com</div>
                    <div className="row">
                      <WhatsAppIcon id="icon" />
                      (34) 3236.7277
                    </div>
                    <div className="row">
                      <a
                        href="https://www.instagram.com/seebuberlandia"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <InstagramIcon id="icon" />
                        @seebuberlandia
                      </a>
                    </div>
                  </div>
                </CorpDataStyled>
              </CardContentRightStyled>
            </CardContentStyled>
          </CardCustomStyled>
          {!isPhotoUpdateRequired && (
            <CardOptionsStyled>
              <ButtonChangePhotoStyled
                id="ButtonStyledChangePhoto"
                fullWidth
                variant="contained"
                type="submit"
                onClick={enableChangePhoto}
              >
                <AddPhotoAlternateIcon className="icon" />
                <span>Alterar foto</span>
              </ButtonChangePhotoStyled>
              <ButtonStyled
                id="ButtonStyledDownload"
                fullWidth
                variant="contained"
                type="submit"
                onClick={handleDownload}
              >
                <DownloadIcon className="icon" />
                <span>Baixar carteira</span>
              </ButtonStyled>
              {/*
              <ButtonStyled
                id="ButtonStyledGoogleWallet"
                fullWidth
                variant="contained"
                type="submit"
                onClick={() => openNewTab(googleWalletUrl)}
                disabled={loadingGoogleWallet}
              >
                <img src={googleWalletButton} width={20} alt="" />
                <span>
                  {loadingGoogleWallet
                    ? 'Carregando...'
                    : 'Adicionar ao Google Wallet'}
                </span>
              </ButtonStyled>
              <ButtonStyled
                id="ButtonStyledAppleWallet"
                fullWidth
                variant="contained"
                type="submit"
                onClick={handleDownload}
              >
                <img src={appleWalletButton} width={20} alt="" />
                <span>Adicionar ao Apple Wallet</span>
              </ButtonStyled>
              */}
            </CardOptionsStyled>
          )}
        </>
      )}
    </>
  );
};

export default CardPage;
