import React, { useState, useRef, memo } from 'react';
import PropTypes from 'prop-types';
import ReactCrop, {
  centerCrop,
  // Crop,
  makeAspectCrop,
  // PixelCrop,
} from 'react-image-crop';
import { canvasPreview } from './canvasPreview';
import { useDebounceEffect } from './useDebounceEffect';
import 'react-image-crop/dist/ReactCrop.css';

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: 'px',
        width: 1000,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  );
}

function CropPhoto(props) {
  const { photoUrl, photoBuffer, onCropChange } = props;

  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const [crop, setCrop] = useState({});
  const [completedCrop, setCompletedCrop] = useState({});
  const [aspect] = useState(3 / 4);
  const [imageSize, setImageSize] = useState({
    width: 0,
    height: 0,
  });

  const scale = 1;
  const rotate = 0;

  const onChange = (c) => {
    console.log('C', c);
    const config = {
      ...c,
      ...{
        imageWidth: imageSize.width,
        imageHeight: imageSize.height,
      },
    };

    onCropChange(config);
    setCompletedCrop(c);
  };

  const onImageLoad = (e) => {
    if (aspect) {
      const { width, height } = e.currentTarget;

      setImageSize({
        width,
        height,
      });

      const cropWidth = Math.min(width, height * aspect);
      const cropHeight = cropWidth / aspect;
      const x = (width - cropWidth) / 2;
      const y = (height - cropHeight) / 2;

      setCrop(centerAspectCrop(width, height, aspect));
      onCropChange({
        unit: 'px',
        width: cropWidth / width,
        height: cropHeight / height,
        x: x / width,
        y: y / height,
      });
    }
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate,
        );
      }
    },
    100,
    [completedCrop],
  );

  return (
    <div>
      <ReactCrop
        crop={crop}
        onChange={(_, percentCrop) => setCrop(percentCrop)}
        onComplete={(c) => onChange(c)}
        aspect={aspect}
      >
        {photoBuffer && (
          <img
            ref={imgRef}
            alt="Crop me"
            src={photoBuffer}
            style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
            onLoad={(e) => {
              URL.revokeObjectURL(photoBuffer);
              onImageLoad(e);
            }}
          />
        )}
        {photoUrl && (
          <img
            ref={imgRef}
            alt="Crop me"
            src={photoUrl}
            style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
            onLoad={onImageLoad}
          />
        )}
      </ReactCrop>
      {/* <div>
        {!!completedCrop && (
          <canvas
            ref={previewCanvasRef}
            style={{
              border: '1px solid black',
              objectFit: 'contain',
              width: completedCrop.width,
              height: completedCrop.height
            }}
          />
        )}
      </div> */}
    </div>
  );
}

CropPhoto.propTypes = {
  onCropChange: PropTypes.func,
  photoBuffer: PropTypes.any,
  photoUrl: PropTypes.string,
};

export default memo(CropPhoto);
