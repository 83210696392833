import * as React from 'react';
import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';

const TextFieldMask = React.forwardRef(function TextFieldMask(props, ref) {
  const { onChange, mask, definitions, placeholder, ...other } = props;

  const definitionsDefault = {
    '#': /[1-9]/,
  };

  return (
    <IMaskInput
      {...other}
      mask={mask}
      definitions={definitions || definitionsDefault}
      unmask={true}
      placeholder={placeholder}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

TextFieldMask.propTypes = {
  inputRef: PropTypes.any.isRequired,
  placeholder: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  mask: PropTypes.string.isRequired,
  definitions: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

export default TextFieldMask;
