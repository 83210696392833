import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomePage from '../../pages/Home';
import CardPage from '../../pages/Card';

const RouterApp = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" Component={HomePage} />
        {/* <Route path="/card/:id" Component={CardPage} /> */}
        <Route path="/card" Component={CardPage} />
      </Routes>
    </BrowserRouter>
  );
};

export default RouterApp;
