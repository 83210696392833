import base64Utils from '../../utils/base64Utils';

const endpoints = {
  store(data) {
    const userData = base64Utils.encode(data);
    localStorage.setItem('associate', userData);

    return userData;
  },
  get() {
    const item = localStorage.getItem('associate');

    if (item) {
      return base64Utils.decode(item);
    }

    return item;
  },
  update(data) {
    const actual = this.get();

    if (actual) {
      const updated = {
        ...actual,
        ...data,
      };

      return this.store(updated);
    }

    return actual;
  },
};

export default endpoints;
