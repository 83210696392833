const mapped = {
  encode(jsonObject) {
    if (!jsonObject) {
      return null;
    }

    let text = jsonObject;

    if (
      typeof jsonObject === 'object' &&
      jsonObject !== null &&
      !Array.isArray(jsonObject)
    ) {
      text = JSON.stringify(jsonObject);
    }

    return btoa(text);
  },
  decode(base64Encoded) {
    const decoded = atob(base64Encoded).toString();
    return JSON.parse(decoded);
  },
};

export default mapped;
