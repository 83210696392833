const BASE_URL_API = process.env.REACT_APP_BASE_URL_API;
const contentTypeFormData = {
  'Content-Type': 'multipart/form-data;',
};

const mapped = {
  buildAxiosBaseUrl(endpoint) {
    console.log(BASE_URL_API, endpoint);
    return `${BASE_URL_API}/${endpoint}`;
  },
  buildAxiosConfig(headers, params, contentType) {
    if (contentType === 'form-data') {
      contentType = contentTypeFormData;
    }

    return {
      headers: headers || {},
      params,
      contentType,
    };
  },
};

export default mapped;
