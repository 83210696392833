import html2canvas from 'html2canvas';

const mapped = {
  screenshot(elementRef, filename) {
    if (!elementRef.current) {
      return;
    }

    html2canvas(elementRef.current, {
      letterRendering: 1,
      allowTaint: true,
      useCORS: true,
    }).then((canvas) => {
      const imgData = canvas.toDataURL('image/jpeg');

      const link = document.createElement('a');
      link.href = imgData;
      link.download = filename;

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
    });
  },
};

export default mapped;
