import { Typography } from '@mui/material';
import styled from 'styled-components';

export const FooterStyled = styled.div`
  width: 100%;
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export const FooterDividerStyled = styled.div`
  width: 100%;
  height: 1px;
  background: #dddddd;
  margin: 10px 0;
`;

export const FooterTextTitleStyled = styled.div`
  margin: 15px 0;
`;

export const FooterTextItemStyled = styled(Typography)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px !important;

  span {
    margin-left: 10px;
  }
`;
