import * as React from 'react';
import PropTypes from 'prop-types';
import TextFieldMask from '../TextFieldMask';

const TextFieldMaskDate = React.forwardRef(function TextFieldMaskDate(
  props,
  ref,
) {
  const { onChange, ...other } = props;
  const mask = '00/00/0000';
  const placeholder = 'dd/mm/aaaa';

  return (
    <TextFieldMask
      {...other}
      mask={mask}
      onChange={onChange}
      inputRef={ref}
      placeholder={placeholder}
    />
  );
});

TextFieldMaskDate.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default TextFieldMaskDate;
