import axios from 'axios';
import httpUtils from '../../utils/httpUtils';

const baseEndpoint = 'associate';

const endpoints = {
  validateCard(params) {
    return axios.get(
      `${httpUtils.buildAxiosBaseUrl(baseEndpoint)}/validateCard`,
      httpUtils.buildAxiosConfig({}, params),
    );
  },
  cropAndUpdatePhoto(data) {
    return axios.post(
      `${httpUtils.buildAxiosBaseUrl(baseEndpoint)}/cropAndUpdatePhoto`,
      data,
      httpUtils.buildAxiosConfig({}, {}, 'form-data'),
    );
  },
  getGoogleWalletOptions(params) {
    return axios.get(
      `${httpUtils.buildAxiosBaseUrl(baseEndpoint)}/googleWalleOptions`,
      httpUtils.buildAxiosConfig({}, params),
    );
  },
};

export default endpoints;
