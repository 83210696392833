import React from 'react';
import RouterApp from '../router';
import './index.css';
import Footer from '../footer';
import { ContainerPageStyled, ContainerStyled } from './style';

const Main = () => {
  // let deferredPrompt;

  // function showInstallPromotion() {
  //   const installBanner = document.createElement("div");
  //   installBanner.className = "install-banner";

  //   const installText = document.createElement("p");
  //   installText.textContent = "Install this app for a better experience!";

  //   const installButton = document.createElement("button");
  //   installButton.textContent = "Install";
  //   installButton.addEventListener("click", installApp);

  //   installBanner.appendChild(installText);
  //   installBanner.appendChild(installButton);

  //   document.body.appendChild(installBanner);
  // }

  // function installApp() {
  //   deferredPrompt.prompt();
  //   deferredPrompt.userChoice.then((choiceResult) => {
  //     if (choiceResult.outcome === "accepted") {
  //       console.log("User accepted the A2HS prompt");
  //     } else {
  //       console.log("User dismissed the A2HS prompt");
  //     }

  //     deferredPrompt = null;
  //   });
  // }

  // window.addEventListener("beforeinstallprompt", (e) => {
  //   e.preventDefault();
  //   deferredPrompt = e;
  //   showInstallPromotion();
  // });

  return (
    <ContainerStyled id="Container">
      <ContainerPageStyled id="ContainerPage">
        <RouterApp id="Router" />
        <Footer id="Footer" />
      </ContainerPageStyled>
    </ContainerStyled>
    // <div>
    //   <button onClick={installApp}>INSTALAR</button>
    // </div>
  );
};

export default Main;
