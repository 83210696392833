import styled from 'styled-components';

export const HeaderStyled = styled.div`
  display: flex;
  align-items: center;
  padding: 30px 0;
  width: 100%;
`;

export const LogoStyled = styled.img`
  width: 100px;
  margin-right: 20px;
  border-radius: 10px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
`;
