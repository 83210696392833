import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Formik, Form, Field } from 'formik';
import {
  ContainerStyled,
  BodyStyled,
  ErrorMessageStyled,
  FormTitleStyled,
  FormSubTitleStyled,
  FormContainerStyled,
  TextFieldStyled,
  ButtonStyled,
  LinkAssociateStyled,
  ErrorMessageFormStyled,
} from './style';
import Header from '../../containers/header';
import TextFieldMaskCpf from '../../components/TextFieldMaskCpf';
import TextFieldMaskDate from '../../components/TextFieldMaskDate';
import associateService from '../../services/http/associateService';
import stringUtils from '../../utils/stringUtils';
import associateDataService from '../../services/store/associateDataService';

const HomePage = () => {
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [formErrorMessage, setFormErrorMessage] = useState(null);
  const inputRef = useRef(null);
  const navigate = useNavigate();

  const validate = (values) => {
    const errors = {};

    if (!values.cpf) {
      errors.cpf = 'CPF é obrigatório';
    }

    if (!values.bank_registration_number) {
      errors.bank_registration_number = 'Matrícula é obrigatória';
    }

    if (!values.date_admission) {
      errors.date_admission = 'Data de admissão é obrigatória';
    }

    return errors;
  };

  const handleFormSubmit = async (values) => {
    setLoadingSubmit(true);

    const defaultErrorMessage =
      'Por favor, entre em contato com nossa equipe. Os dados de contato, estão no final dessa página.';

    const params = {
      cpf: stringUtils.maskCPF(values.cpf),
      date_admission: stringUtils.maskDate(values.date_admission),
      bank_registration_number: values.bank_registration_number,
    };

    associateService
      .validateCard(params)
      .then((response) => {
        const { data } = response;

        if (data.status === 'error') {
          setFormErrorMessage(`${data.message} ${defaultErrorMessage}`);
          console.error('Error:', data);
        } else {
          setFormErrorMessage(null);
          associateDataService.store(response.data);
          navigate('/card');
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        setFormErrorMessage(
          `Ocorreu um erro em nosso sistema no momento de validar seus dados. ${defaultErrorMessage}`,
        );
      })
      .finally(() => {
        setLoadingSubmit(false);
      });
  };

  const initialValues = {
    cpf: '',
    bank_registration_number: '',
    date_admission: '',
  };

  const renderTextFieldError = (meta) => {
    return (
      <>
        {meta.touched && meta.error && (
          <ErrorMessageStyled>{meta.error}</ErrorMessageStyled>
        )}
      </>
    );
  };

  const closeErrorMessage = () => {
    setFormErrorMessage(null);
  };

  const renderFormError = () => {
    if (!formErrorMessage) {
      return null;
    }

    return (
      <ErrorMessageFormStyled>
        {formErrorMessage}
        <div className="close">
          <IconButton
            id="ErrorMessageFormStyledButton"
            onClick={closeErrorMessage}
          >
            <CloseIcon color="" />
          </IconButton>
        </div>
      </ErrorMessageFormStyled>
    );
  };

  useEffect(() => {
    // const cardData = associateDataService.get();

    // if (cardData?.id) {
    //   navigate('/card');
    //   return;
    // }

    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <>
      <Header id="Header" />
      <ContainerStyled>
        <Formik
          initialValues={initialValues}
          validate={validate}
          onSubmit={handleFormSubmit}
        >
          {() => (
            <Form style={{ width: '100%' }} id="form">
              <BodyStyled id="BodyStyled">
                <FormTitleStyled>Gerar carteira de convênio</FormTitleStyled>
                <FormSubTitleStyled id="FormSubTitleStyled">
                  Por favor, preencha o formulário abaixo para validar seus
                  dados.
                </FormSubTitleStyled>

                {renderFormError()}

                <FormContainerStyled id="FormSubTitleStyled">
                  <Field name="bank_registration_number">
                    {({ field, meta }) => (
                      <>
                        <TextFieldStyled
                          id="TextFieldStyledBankRegistrationNumber"
                          inputRef={inputRef}
                          fullWidth
                          {...field}
                          label="Matrícula no banco"
                          variant="outlined"
                        />
                        {renderTextFieldError(meta)}
                      </>
                    )}
                  </Field>
                  <Field name="cpf">
                    {({ field, meta }) => (
                      <>
                        <TextFieldStyled
                          id="TextFieldStyledCpf"
                          fullWidth
                          {...field}
                          label="CPF"
                          variant="outlined"
                          InputProps={{
                            inputComponent: TextFieldMaskCpf,
                          }}
                        />
                        {renderTextFieldError(meta)}
                      </>
                    )}
                  </Field>
                  <Field name="date_admission">
                    {({ field, meta }) => (
                      <>
                        <TextFieldStyled
                          id="TextFieldStyledDateAdmission"
                          fullWidth
                          {...field}
                          label="Data de admissão"
                          variant="outlined"
                          InputProps={{
                            inputComponent: TextFieldMaskDate,
                          }}
                        />
                        {renderTextFieldError(meta)}
                      </>
                    )}
                  </Field>
                  <ButtonStyled
                    id=""
                    fullWidth
                    variant="contained"
                    type="submit"
                    disabled={loadingSubmit}
                  >
                    Verificar
                  </ButtonStyled>
                  <LinkAssociateStyled id="LinkAssociateStyled">
                    <a
                      href="https://www.bancariosuberlandia.org.br/seja-socio"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Ainda não está afiliado? Clique aqui.
                    </a>
                  </LinkAssociateStyled>
                </FormContainerStyled>
              </BodyStyled>
            </Form>
          )}
        </Formik>
      </ContainerStyled>
    </>
  );
};

export default HomePage;
