import styled from 'styled-components';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import cardBackground from '../../assets/image/card-background.jpg';

export const CardStyled = styled(Card)`
  margin-bottom: 15px;
`;

export const CardCustomStyled = styled.div`
  margin-bottom: 15px;
  background: #ffffff;
  border-radius: 5px;
  border: 1px solid #dddddd;
`;

export const CardHeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px;
  background: #003456;
  border-radius: 5px 5px 0 0;
`;

export const CardHeaderLogoContainerStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
`;

export const LogoStyled = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 10px;
`;

export const CardHeaderTitleStyled = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  flex-direction: column;
  padding-left: 15px;
  color: #ffffff;

  h3 {
    margin: 0;
    font-size: 30px;
  }
`;

export const CardContentStyled = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px;
`;

export const CardContentLeftStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .empty-photo {
    width: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    color: #555555;
    border: 1px solid #dddddd;
    border-radius: 5px;
  }

  .empty-photo .text {
    text-align: center;
    font-size: 12px;
    margin-top: 10px;
  }

  .empty-photo .icon {
    font-size: 30px;
  }
`;

export const UserPhotoStyled = styled.img`
  width: 100px;
`;

export const CardContentRightStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 15px;
  background: url('${cardBackground}');
  background-position: center;
  background-size: cover;
`;

export const UserDataStyled = styled.div`
  display: flex;
  flex-direction: column;

  .row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .col {
    display: flex;
    flex-direction: column;
    width: 165px;
  }

  .label {
    font-size: 10px;
  }

  .value {
    font-size: 14px;
    font-weight: bold;
  }
`;

export const CorpDataStyled = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  padding-top: 10px;
  margin-top: 10px;
  border-top: 1px solid #dddddd;

  .contact {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
  }

  .row,
  .row a {
    display: flex;
    align-items: center;
    font-size: 12px;
    margin-top: 3px;
  }

  .row a {
    color: #555555;
    text-decoration: none;
  }

  #icon {
    font-size: 18px;
    margin-right: 5px;
  }
`;

export const WarningMessageCardStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  background: #e7a552;
  font-size: 14px;
  margin: 15px 0;
  padding: 10px 15px;
  border-radius: 5px;

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #WarningMessageCardStyledButton {
    color: #ffffff;
    margin-right: 15px;
  }
`;

export const ErrorMessageCardStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  background: #e75252;
  font-size: 14px;
  margin: 15px 0;
  padding: 10px 15px;
  border-radius: 5px;

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #ErrorMessageCardStyledButton {
    color: #ffffff;
    margin-right: 15px;
  }

  .list,
  .item {
    margin-top: 5px;
  }
`;

export const UploadPhotoStyled = styled.div`
  padding: 15px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
  }

  .header h3 {
    margin: 0;
  }
`;

const getBorderDropzoneColor = (props) => {
  if (props.isFocused) {
    return '#002a63';
  }

  return '#aaaaaa';
};

export const UploadPhotoDropzoneComponentStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px 0;
  margin: 15px 0;
  border: 2px dashed ${(props) => getBorderDropzoneColor(props)};
  transition: border 0.25s ease-in-out;
`;

export const ButtonLoadingStyled = styled(LoadingButton)`
  margin-top: 20px !important;
  background: #4ca2ff !important;
`;

export const CardOptionsStyled = styled.div`
  display: flex;
  justify-content: space-between;

  #ButtonStyledDownload {
    margin-left: 0 !important;
  }

  #ButtonStyledAppleWallet {
    margin-right: 0 !important;
  }
`;

export const ButtonStyled = styled(Button)`
  display: flex;
  flex-grow: 1;
  margin: 0 !important;
  padding: 10px 10px !important;
  background: #1f1f1f !important;

  span {
    font-size: 12px;
    text-transform: none;
    color: #ffffff;
  }

  img,
  .icon {
    width: 25px;
    margin-right: 5px;
  }
`;

export const ButtonChangePhotoStyled = styled(Button)`
  margin: 0 10px 0 0 !important;
  padding: 10px 10px !important;
  background: #4ca2ff !important;

  span {
    font-size: 12px;
    text-transform: none;
    color: #ffffff;
  }

  img,
  .icon {
    width: 25px;
    margin-right: 5px;
  }
`;
