const mapped = {
  maskCPF(cpf) {
    cpf = cpf.replace(/\D/g, '');
    cpf = cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');

    return cpf;
  },
  maskDate(date) {
    const year = date.substring(4);
    const month = date.substring(2, 4);
    const day = date.substring(0, 2);

    return `${year}-${month}-${day}`;
  },
};

export default mapped;
