import React from 'react';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import PinDropIcon from '@mui/icons-material/PinDrop';
import { Typography } from '@mui/material';
import {
  FooterDividerStyled,
  FooterStyled,
  FooterTextItemStyled,
  FooterTextTitleStyled,
} from './style';

const Footer = () => {
  return (
    <FooterStyled>
      <Typography variant="h6">Dúvidas?</Typography>
      <FooterTextTitleStyled>
        <Typography variant="body1" fontWeight="bold">
          Sindicato Bancário de Uberlândia
        </Typography>
        <Typography variant="body1">
          Estamos a disposição para resolver.
        </Typography>
      </FooterTextTitleStyled>
      <FooterTextItemStyled variant="body1">
        <WhatsAppIcon />
        <a
          href="https://wa.me/553432367277/?text=Estou%20com%20uma%20dúvida%20sobre%20a%20geração%20da%20carteira%20de%20convênio%20no%20site"
          target="_blank"
          rel="noreferrer"
        >
          <span>Iniciar conversa</span>
        </a>
      </FooterTextItemStyled>
      <FooterDividerStyled />
      <FooterTextItemStyled variant="body1">
        <PhoneIcon />
        <span>(34) 3236-7277</span>
      </FooterTextItemStyled>
      <FooterDividerStyled />
      <FooterTextItemStyled variant="body1">
        <EmailIcon />
        <span>bancariosuberlandia@gmail.com</span>
      </FooterTextItemStyled>
      <FooterDividerStyled />
      <FooterTextItemStyled variant="body1">
        <PinDropIcon />
        <span>
          R. Duque de Caxias, 95, CEP 38400-142
          <br />
          Centro, Uberlândia - MG
          <br />
          <a
            href="https://maps.app.goo.gl/XDm6SPUnVAMwZeJn6"
            target="_blank"
            rel="noreferrer"
          >
            como chegar
          </a>
        </span>
      </FooterTextItemStyled>
    </FooterStyled>
  );
};

export default Footer;
