import * as React from 'react';
import PropTypes from 'prop-types';
import TextFieldMask from '../TextFieldMask';

const TextFieldMaskCpf = React.forwardRef(function TextFieldMaskCpf(
  props,
  ref,
) {
  const { onChange, ...other } = props;
  const mask = '000.000.000-00';
  const placeholder = '000.000.000-00';

  return (
    <TextFieldMask
      {...other}
      mask={mask}
      onChange={onChange}
      inputRef={ref}
      placeholder={placeholder}
    />
  );
});

TextFieldMaskCpf.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default TextFieldMaskCpf;
