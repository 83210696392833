import React from 'react';
import { Typography } from '@mui/material';
import { HeaderStyled, LogoStyled } from './style';
import logo from '../../assets/image/logo.jpg';

const Header = () => {
  return (
    <HeaderStyled>
      <LogoStyled src={logo} alt="Logo seeb" />
      <div>
        <Typography variant="h4">Carteira SEEB</Typography>
        <Typography variant="body1">
          Aproveite os benefícios dos convênios do Sindicato Bancário de
          Uberlândia e Região
        </Typography>
      </div>
    </HeaderStyled>
  );
};

export default Header;
