// import samplePass from "./assets/image/sample-pass.png";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import './style/App.css';
import React from 'react';
import Main from './containers/main';

function App() {
  return <Main />;
}

export default App;
